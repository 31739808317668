:root {
  --primary-color: #5e72e4;
  --background-color: #2c3752;
  --secondary-background-color: #efefef;
}

.main_button {
  padding: 8px 16px;
  border-radius: 5px;
  background-color: #5e72e4;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border: none;

  &:hover {
    background-color: #2a56de;
  }

  &:disabled {
    background-color: #9fa9e2;
    cursor: not-allowed;
  }
}
