.header_container {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #1f273f;
  border-bottom: 1px solid #2c3752;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 10;

  h1 {
    background: -webkit-linear-gradient(45deg, #a686f2, #66bbed);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    padding: 12px 24px;
    font-size: 28px;
  }


  .header_link_container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0px 24px;
    .header_link {
      color: #ededed;
      font-size: 16px;
      margin: 0px 12px;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
    }

    .header_link_active {
      color: #fff;
      font-weight: bold;
    }
  }

  .header_button_container {
    padding: 12px 24px;
  }
}