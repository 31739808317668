.post_container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--secondary-background-color);

  .post_list_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    padding: 30px 0;
    overflow-y: auto;
    gap: 25px;
  }
}

.single_post_container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--secondary-background-color);
}

.post_add_post_button {
  position: fixed;
  bottom: 20px;
  right: 40px;
}
